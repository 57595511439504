<template>
  <div class="content-container">
    <PageTitle :title="$t('EXTRA_GAMES.TITLE')">
      <template #extra-content>
        <!-- TODO: On click, the 'Send new request' popup is displayed (6.1.0). -->
        <router-link :to="{name: ROUTE_NAMES_CMS.CREATE_EXTRA_GAME }">
          <button
            type="button"
            class="pink-button create-button">
            {{ $t('EMPTY_TABLE.EXTRA_GAMES.NEW_EXTRA_GAME') }}
          </button>
        </router-link>
      </template>
    </PageTitle>
    <h1 class="page-section-title section-title">{{ $t('EXTRA_GAMES.OVERVIEW') }}</h1>

    <CustomTable
      :options="extraGamesTableOptions"
      :table-data="extraGames.items"
      :pagination-data="extraGames"
      :search-bar-options="
        {
          placeholder: $t('EXTRA_GAMES.SEARCH'),
          searchId: 1
        }"
      :search-function="manageSearch"
      :empty-state-data="emptyStateData">
      <template #order="props">
        <div>{{ props.rowData.id }}</div>
      </template>
      <template #title="props">
        <div>{{ props.rowData.name }}</div>
      </template>
      <template #partnerLink="props">
        <div> <a class="extra-link" :href="props.rowData.link">{{ props.rowData.link }}</a></div>
      </template>
      <template #bits="props">
        <div>{{ props.rowData.bitsAwarded }}</div>
      </template>
      <template #difficulties="props">
        <div>{{ formatDifficulties(props.rowData.difficulties, ' en ') }}</div>
      </template>
      <template #actions="props">
        <div class="action-button-group">
          <ActionButton :text="$t('DELETE')"
                        :icon="require('../../../assets/icons/icn_delete.svg')"
                        custom-class="button-red"
                        class="action"
                        @click="preDeleteExtraGame(props.rowData)"/>
          <ActionButton :text="$t('EDIT')"
                        :icon="require('../../../assets/icons/icn_edit.svg')"
                        custom-class="button-blue"
                        class="action"
                        @click="editExtraGame(props.rowData)"/>
        </div>
      </template>
      <template #right-side-info>
        <p> <b> {{ extraGames.totalItems || 0 }} </b>{{ $t('EXTRA_GAMES.TOTAL_QUESTIONS') }} </p>
      </template>
    </CustomTable>
    <BasicModal v-if="isBasicVersionDisplayed"/>
  </div>
</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'
// import Search from '@src/components/elements/selectSchools/Search'
import CustomTable from '@/components/table/CustomTable'
import { getTableOptions, EXTRA_GAMES, formatDifficulties } from '@/utils/helpers/customTable/CustomTableHelper'
import { createEmptyTableData } from '@/utils/helpers/customTable/EmptyTableHelper'

import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import { GET_EXTRA_GAMES, DELETE_EXTRA_GAME } from '@/store/modules/cms/actions'
import ActionButton from '@/components/table/ActionButton'
import { computed, ref } from '@vue/reactivity'
import { ROUTE_NAMES_CMS } from '@/router/modules/cms'
import nl from '@/utils/language/nl.json'
import ModalController, { MODAL_NAMES } from '@/utils/helpers/basicModal/ModalController'
import { BASIC_MODAL_CONTENT } from '@/models/basicModal/BasicModalContent'
import BasicModal from '@/components/elements/basicModal/BasicModal'

export default {
  name: 'ExtraGames',
  components: {
    PageTitle,
    // Search,
    CustomTable,
    BasicModal,
    ActionButton
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()


    const modalController = ref(new ModalController())

    const activeModal = computed(() => store.getters.getActiveBasicModal)

    const isBasicVersionDisplayed = computed(() =>
      activeModal.value === MODAL_NAMES.DELETE_EXTRA_GAME
    )

    //missing pagination data
    const paginationData = computed(() => {
      return {
        totalPages: extraGames.totalPages,
        currentPage: extraGames.currentPage
      }
    })

    function editExtraGame(gameData) {
      router.push({
        name: ROUTE_NAMES_CMS.EDIT_EXTRA_GAME,
        params: gameData
      })
    }

    function createExtraGame() {
      router.push({ name: ROUTE_NAMES_CMS.CREATE_EXTRA_GAME })
    }

    const emptyStateData = createEmptyTableData('EXTRA_GAMES', createExtraGame, require('@/assets/icons/icn_puzzle.svg'))


    function manageSearch(payload) {
      console.log('extra games payload', payload)
      getExtraGames(payload)
    }

    function getExtraGames(payload) {
      //console.log(extraGames)
      return store.dispatch(GET_EXTRA_GAMES, payload)
    }

    const extraGames = computed(() => store.getters.getExtraGames)


    function preDeleteExtraGame(extraGame) {
      const title = extraGame.name
      modalController.value.setModal(BASIC_MODAL_CONTENT.DELETE_EXTRA_GAME(title), () => postDeleteExtraGame(extraGame.id), null, null, MODAL_NAMES.DELETE_EXTRA_GAME)
    }

    function postDeleteExtraGame(extraGame) {
      store.dispatch(DELETE_EXTRA_GAME, extraGame).then(response => {
        if (response) {
          modalController.value.resetActiveModal()
          getExtraGames({})
        }
      })
    }

    return {
      ROUTE_NAMES_CMS,

      extraGames,
      activeModal,
      modalController,
      MODAL_NAMES,
      paginationData,
      extraGamesTableOptions: getTableOptions(EXTRA_GAMES),

      preDeleteExtraGame,
      editExtraGame,
      emptyStateData,
      manageSearch,

      isBasicVersionDisplayed,
      formatDifficulties
    }
  },
  template: 'ExtraGames'
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.create-button {
  font-size: rem(16);
  padding: rem(11) rem(22);
}
</style>
