import nl from '@/utils/language/nl.json'

export function createEmptyTableData(translationKey, buttonAction, icon) {
  return {
    title: nl.EMPTY_TABLE[translationKey].TITLE,
    text: nl.EMPTY_TABLE[translationKey].TEXT,
    buttonText: nl.EMPTY_TABLE[translationKey].BUTTON_TEXT,
    buttonAction: buttonAction,
    icon: icon
  }
}


export function createNoResultsData(translationKey, icon, searchTerm) {
  return {
    title: nl.NO_RESULTS_TABLE[translationKey].TITLE,
    text: nl.NO_RESULTS_TABLE[translationKey].TEXT.replace('{search}', searchTerm),
    icon: icon
  }
}